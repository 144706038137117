import client from "../toolApi"
import { DataLayer } from "../../helpers/GA"
export const PowerPointToPdf = (data, setDownload) =>
  client
    .post("/uploadNew", data.formData)
    .then(res => {
      window.fileName = res.data.message[0]
      DataLayer("Online tool", "Upload", "PowerPoint to pdf")
      client
        .get(`/pptx2pdfTool?fileName=${window.fileName}`)
        .then(res => {
          DataLayer("Online tool", "Convert", "PowerPoint to pdf")
          client
            .get(`/downloadPPTX2PDF?fileName=${window.fileName}`, {
              responseType: "blob",
            })
            .then(res => {
              console.log("Downloaded: ", res)
              const link = document.createElement("a")
              setDownload(link)
              link.href = URL.createObjectURL(new Blob([res.data]))
              link.download = data.fileName.split(".")[0] + ".pdf"
              document.body.appendChild(link)
              link.click()
              DataLayer("Online tool", "Download", "PowerPoint to pdf")
            })
            .catch(() => {
              DataLayer("Online tool", "Error download", "PowerPoint to pdf")
            })
        })
        .catch(() => {
          DataLayer("Online tool", "Error convert", "PowerPoint to pdf")
        })
    })
    .catch(() => {
      DataLayer("Online tool", "Error upload", "PowerPoint to pdf")
    })
